<template>
  <div>
    <section class="grey darken-4 curved" style="min-height: 80vh">
      <v-container class="white--text">
        <h1 v-scrollanimation class="text-center pt-8" style="font-size: 42px">
          Who or what is Aetera?
        </h1>
        <v-row align="center" style="min-height: 60vh">
          <v-col class="px-md-12 px-lg-12" cols="12" md="6">
            <h2 v-scrollanimation style="transition-delay: 0.2s">
              The Aetera Aqua Token
            </h2>
            <p v-scrollanimation style="transition-delay: 0.2s">
              The Aetera Aqua Token is the next step in the spring water
              evolution and will change the way water is seen in the digital
              world
            </p>
            <p v-scrollanimation style="transition-delay: 0.2s">
              The Aetera token will be linked to the spring water price in the
              UK and will be a redeemable token that will afford the client
              untouched pure spring water taken directly from the source deep
              within the hill ranges of the Daskrans Farm in rural South Africa
            </p>
            <p v-scrollanimation style="transition-delay: 0.2s">
              Scientific reports will be given on the quality of said water at
              the time of bottling and will be issued with the token upon
              purchase
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <div>
              <v-img
                eager
                class="mx-auto mb-xs-8"
                src="@/assets/watertoken.png"
                max-height="480px"
                contain
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="white--text" style="min-height: 75vh">
      <h1 class="text-center pt-16" style="font-size: 42px">
        Important features of Aetera
      </h1>
      <v-container>
        <v-row
          fill-height
          class="pt-12"
          style="min-height: 55vh"
          align="center"
        >
          <v-col cols="12" md="4">
            <v-card class="transparent" flat>
              <v-img
                eager
                contain
                src="@/assets/features/featurefour2.png"
                max-width="100%"
                max-height="148px"
              ></v-img>
              <h2 class="text-center white--text justify-center"
                >Access to untouched water</h2
              >
              <p class="text-center white--text justify-center"
                >Access to water that has been untouched and filtered through
                naturally found underground filters, the purest water one can
                find anywhere today, the way our ancestors drank
                it.</p
              >
              <v-card-actions class="justify-center">
                <!-- <v-btn tile>Learn More</v-btn> -->
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="transparent align-center" flat>
              <v-img
                eager
                contain
                src="@/assets/features/featurefour.png"
                max-width="100%"
                max-height="148px"
              ></v-img>
              <h2 class="text-center white--text justify-center"
                >Proven Results from day one</h2
              >
              <p class="text-center white--text justify-center"
                >All certifications and water testing results will be available
                from day of purchase and will be available to the client
                whenever the client deems necessary. Full spec water test
                including mineral compositions.</p
              >
              <v-card-actions class="justify-center">
                <!-- <v-btn tile>Learn More</v-btn> -->
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="transparent" flat>
              <v-img
                eager
                contain
                src="@/assets/features/featurefour1.png"
                max-width="100%"
                max-height="148px"
              ></v-img>
              <h2 class="text-center white--text justify-center"
                >Giving back where necessary</h2
              >
              <p class="text-center white--text justify-center"
                >Whenever you invest in Aetera Aqua Token, you also invest a
                portion towards charity efforts. All of this information and how
                your investment will be used for charity efforts will be sent to
                you on request</p
              >
              <v-card-actions class="justify-center">
                <!-- <v-btn tile>Learn More</v-btn> -->
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="grey darken-4">
      <v-container class="white--text">
        <v-row align="center" style="min-height: 60vh">
          <v-col cols="12" md="6">
            <v-img
              eager
              class="mx-auto"
              contain
              src="@/assets/logowithgold.png"
              max-height="480px"
            ></v-img>
          </v-col>
          <v-col class="px-md-12 px-lg-12" cols="12" md="6">
            <h2 v-scrollanimation style="transition-delay: 0.2s">
              The importance of the Aetera Aqua Token
            </h2>
            <p v-scrollanimation style="transition-delay: 0.2s">
              The Aetera Aqua Token will provde prospective clients with a
              source of untouched spring water from deep withing the range of
              hills found on the Daskrans farm in South Africa
            </p>
            <p v-scrollanimation style="transition-delay: 0.2s">
              This will give the client the opportunity to have a supply of some
              of the purest water that is packed with minerals from the natural
              filters it goes through while travelling through the earth
            </p>
            <p v-scrollanimation style="transition-delay: 0.2s">
              All purchases also fund charity programs to provide water to those
              who need it, some of the dryest areas reside in Africa and Aetera
              wants to leave it's mark on this area by becoming a supporter to
              those who have little or no access to clean water sources
            </p>
            <p v-scrollanimation style="transition-delay: 0.2s">
              A certificate with purchase will provide important information to
              the client pertaining to individual details linked to the water
              purchased such as, quality of water, charity funded, water given
              to which area of village, the mineral composition of the water
              sourced and more.
            </p>
            <p v-scrollanimation style="transition-delay: 0.2s">
              Leave your mark on the world with Aetera Aqua Token and become a
              part of the solution. In a world where clean water is scarce,
              create a solution not only for yourself but also for people who
              need it.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="white--text pt-12 parallax2" style="min-height: 65vh">
      <h1 class="text-center" style="font-size: 42px">How do I invest?</h1>
      <v-container>
        <v-row justify="center">
          <v-col class="justify-center text-center" cols="12" md="6">
            <v-img
              eager
              class="mx-auto"
              src="@/assets/bankcards.png"
              max-width="148px"
            ></v-img>
            <h1 style="font-size: 24px">With a card</h1>
          </v-col>
          <v-col class="justify-center text-center" cols="12" md="6">
            <v-img
              eager
              class="mx-auto"
              src="@/assets/coins.png"
              max-width="148px"
            ></v-img>
            <h1 style="font-size: 24px">With cryptocurrency</h1>
          </v-col>
        </v-row>
        <v-row class="mt-md-16" justify="center">
          <v-card max-width="480px">
            <v-card-title style="word-break: break-word ; font-family: Orbitron;"
              >Easily buy the Aetera Aqua token</v-card-title
            >
            <v-card-subtitle style="font-family:Quicksand"
              >Use either cryptocurrency or your bank card when buying the
              Aetera Aqua Token, a crypto investment that will change your life
              and at the same time might change the life of someone in
              need</v-card-subtitle
            >
            <v-card-actions
              ><v-btn v-if="!isAuthenticated" color="primary" to="/login" block>Invest now</v-btn>
              <v-btn v-if="isAuthenticated" color="primary" to="/dashboard" block>Invest now</v-btn></v-card-actions
            >
          </v-card>
        </v-row>
      </v-container>
    </section>
    <section class="grey darken-4">
      <v-container class="pt-md-16">
        <h1 class="white--text text-center my-16" style="font-size: 48px">
          How do I use the Aetera Aqua Token
        </h1>
        <v-img
          eager
          class="mx-auto mb-16"
          contain
          max-height="320px"
          src="@/assets/longaetera.png"
        ></v-img>
        <v-row align="top" class="px-md-16 mt-4 mb-16">
          <v-col cols="12" md="4" class="text-left white--text">
            <h2>Aetera token will not be on exchanges</h2>
            <p>
              The Aetera token will solely be for redemption of either the most
              valuable resource we pocess, untouched spring water from deep
              within the Daskrans hill range.
            </p>
            <p>
              Or for a Aetera Aqua NFT that will represent a bulk amount of
              water
            </p>
          </v-col>
          <v-col
            v-scrollanimation
            cols="12"
            md="4"
            class="text-left black--text yellow darken-2 px-4"
            style="border-radius: 8px"
          >
            <h2>Aetera Aqua NFTs will be tradeable</h2>
            <p>
              One of the real values of Aetera lie in the tradable function of
              the NFT program that will take place within the Aetera
              infrastructure
            </p>
            <p>
              It will be available to exchange the Aetera Aqua token for a
              limited edition NFT that will represent a bulk amount of spring
              water, this NFT will also be redeemable for that amount of water
              or will be tradeable on leading NFT markets
            </p>
          </v-col>
          <v-col cols="12" md="4" class="text-left white--text">
            <h2>Exchange your token for spring water</h2>
            <p>
              Clients that buy the Aetera Aqua token will be able to exchange
              their tokens for the untouched spring water found deep within the
              Daskrans hill range
            </p>
            <p>
              Clients can do this on the Aetera shop page or can request this
              via an email and instructions will be sent accordingly
            </p>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="parallax">
      <h1 class="white--text text-center mb-8 pt-16" style="font-size: 48px">
        The Aetera Ecosystem & Tokenomics
      </h1>
      <v-container class="white--text text-center">
        <h1 class="text-center mt-8" style="font-size: 42px">Tokenomics</h1>
        <v-img
          eager
          max-width="320px"
          class="mx-auto"
          src="@/assets/100.png"
        ></v-img>
        <v-row justify="center" class="text-center">
          <v-col cols="12" md="6">
            <h2 class="text-center mt-8">
              The token will be 100% owned by the community
            </h2>
            <p class="text-center">
              The management have no reason to own any tokens as the value comes
              from selling the tokens and in turn the resulting exchange for
              Aetera NFTs that will return the tokens to the buying pool
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h1 class="text-center mt-8" style="font-size: 42px">Ecosystem</h1>

            <v-img
              eager
              class="mx-auto my-16"
              max-width="840px"
              src="@/assets/ecosystem.png"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="grey darken-4">
      <v-container class="white--text text-center">
        <v-img
          eager
          class="mx-auto mt-16"
          contain
          max-height="360px"
          src="@/assets/aeteradrop.png"
        ></v-img>
        <h1 style="font-size: 48px" class="my-8">Aetera Aqua NFT</h1>
        <h2>
          The Aetera Aqua NFT will initially be divided into 3 unique categories
        </h2>
        <h3 class="mt-4">
          These 3 categories will not be limited to 3 specific copies of the
          same NFT but will be wide ranges within these 3 catergories
        </h3>
        <v-row justify="center" class="mt-4">
          <p style="max-width: 720px" class="pt-4">
            The 3 categories will include 1000l or 1kl, 10000l or 10kl and
            100000l or 100kl for the initial process and will be 3 unique copies
            of NFTs that will have a limited supply
          </p>
        </v-row>
        <v-row justify="center">
          <p style="max-width: 720px">
            Once these NFTs are sold and the supply of Aetera token that goes
            back into the pool replenishes to a given amount, new unique NFTs
            will produced within the same 3 categories unless there is demand
            for more.
          </p>
        </v-row>
        <v-row justify="center">
          <p style="max-width: 720px">
            Each category contains a certain amount of litres as described
            below, this will be redeemable with Aetera tokens as the stipulated
            amount as One Aetera token = 1l therefore we can come to the
            conclusion that One 1000l NFT will be redeemable for an amount of
            One Thousand (1000) Aetera tokens
          </p>
        </v-row>
        <v-row class="pt-8">
          <v-col cols="12" md="4">
            <h1 style="font-size: 48px">Category 1</h1>
            <h2>1000l/1kl</h2>
          </v-col>
          <v-col cols="12" md="4">
            <h1 style="font-size: 48px">Category 2</h1>
            <h2>10000l/10kl</h2>
          </v-col>
          <v-col cols="12" md="4">
            <h1 style="font-size: 48px">Category 3</h1>
            <h2>100000l/100kl</h2>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    //
  }),

  computed: {
		...mapState(['isAuthenticated'])
	},
	
};
</script>

<style>
.curved {
  position: relative;
  background: #2c3e50;
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 20%;
}
.before-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: all 2s ease-out;
}

.enter {
  opacity: 1;
  transform: translateY(0px);
}

.parallax {
  /* The image used */
  background-image: url("https://images.unsplash.com/photo-1460501501851-d5946a18e552?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");

  /* Full height */
  height: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax2 {
  /* The image used */
  background-image: url("https://firebasestorage.googleapis.com/v0/b/aeteratoken.appspot.com/o/blacktech.png?alt=media&token=21b2524c-c33c-47b6-aa8d-2a6e10971939");

  /* Full height */

  /* Create the parallax scrolling effect */
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
