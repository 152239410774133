import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
// replace the values with your firebase config
var firebaseConfig = {
    apiKey: "AIzaSyCa-Ga9b9XVfPa2MkaaGdFcCUYKP6f8Wa0",
    authDomain: "aeteratoken.firebaseapp.com",
    projectId: "aeteratoken",
    storageBucket: "aeteratoken.appspot.com",
    messagingSenderId: "442113801394",
    appId: "1:442113801394:web:553c3c19e8abd2b02cddab",
    measurementId: "G-S8T4WZV9V1"
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()

// collection ref
const usersCollection = db.collection('users')
const gamesCollection = db.collection('games')

export {
    db,
    auth,
    storage,
    usersCollection,
    gamesCollection
}