<template>
  <v-app >
    <v-app-bar flat app color="transparent" dark height="76px">
      <v-container fill-height>
        <v-toolbar-items>
          <router-link to="/">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="@/assets/logohome1.png"
            transition="scale-transition"
            width="60"
          />
          </router-link>

          <h1 style="font-size:30px">Aetera</h1>
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <v-btn
          tile
          to="/login"
          v-if="!isAuthenticated"
        >
          <span >Invest</span>
        </v-btn>
        <v-btn
          tile
          @click="logout"
          v-if="isAuthenticated"
        >
          <span >Logout</span>
        </v-btn>
        
      </v-container>
    </v-app-bar>
    
    <v-main style="background-color: #09090B" class="pt-0">
      <v-slide-y-reverse-transition mode="out-in">
        <router-view />
      </v-slide-y-reverse-transition>
    </v-main>
    <v-footer padless>
    <v-col
      class="text-center"
      cols="12"
    >
      {{ new Date().getFullYear() }} — <strong>Aetera</strong>
    </v-col>
  </v-footer>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "App",

  data: () => ({
    //
  }),

  computed: {
		...mapState(['isAuthenticated'])
	},
	methods: {
		logout() {
			this.$store.dispatch('logout')
		}
	}
};
</script>
