<template>
  <div>
    <home-top />
    <home-description/>
  </div>
</template>

<script>
import HomeTop from "@/components/Home/HomeTop.vue";
import HomeDescription from "@/components/Home/HomeDescription.vue";

export default {
  name: "HomeView",

  components: {
    HomeTop,
    HomeDescription,
  },
};
</script>
