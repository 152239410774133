<template>
  <div>
    <section >
      <!-- <v-parallax alt="alt" height="850" src="https://images.unsplash.com/photo-1604599340287-2042e85a3802?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80">
      <div class="bubble small"></div>
      <div class="bubble s-medium"></div>
      <div class="bubble medium"></div>
      <div class="bubble large"></div>
      <div class="bubble small-l"></div>
      <v-container class="text-center justify-center align-center">
        <v-img
          class="mx-auto mb-0"
          src="@/assets/logohome.png"
          max-height="350px"
          max-width="300px"
          v-scrollanimation
        ></v-img>
        <h1
          v-scrollanimation
          class="my-0"
          style="
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.63);
            font-size: 124px;
            transition-delay: 0.2s;
          "
        >
          Aetera
        </h1>
        <h2
          v-scrollanimation
          style="
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.63);
            transition-delay: 0.4s;
          "
        >
          The Digital Spring Water
        </h2>
        <p
          v-scrollanimation
          style="
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.63);
            font-size: 24px;
            transition-delay: 0.63s;
          "
        >
          Site sourced pure water bliss combined with decentralised technology
        </p>
        <p
          v-scrollanimation
          style="
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.63);
            font-size: 24px;
            transition-delay: 0.63s;
          "
        >
          Coming Soon
        </p>
      </v-container>
    </v-parallax> -->
    </section>
    <section style="height:90vh">
      <v-container class="text-center justify-center align-center">
        <v-row justify="center" align="center">
          <v-col class="mt-16" justify="center" align="center">
            <!-- <iframe
            id="ytplayer"
            type="text/html"
            width="640"
            height="360"
            src="https://www.youtube.com/embed/sov6iz8SRRE?playlist=sov6iz8SRRE&loop=1;rel=0&autoplay=1&controls=0&showinfo=0"
            frameborder="0"
          ></iframe> -->
            <video class="hidden-md-and-up" width="320" height="240" loop  autoplay muted>
              <source src="@/assets/video/aeteratoken.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <video class="hidden-sm-and-down" width="640" height="480" loop  autoplay muted>
              <source src="@/assets/video/aeteratoken.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </v-col>
          <v-col class="white--text" cols="12">
            <h1>Aetera Aqua Token</h1>
            <p>Digital Spring Water</p>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Quicksand&display=swap");
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap');

h1 {
  font-family: 'Orbitron', sans-serif !important;
  font-weight: 300;
  font-size: 64px;
}

h2 {
  font-family: "Quicksand", sans-serif;
  font-size: 32px;
}

p {
  font-family: "Quicksand", sans-serif;
}

.before-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: all 2s ease-out;
}

.enter {
  opacity: 1;
  transform: translateY(0px);
}
.bubble {
  position: absolute;
  top: 53%;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.8);
}
.small {
  width: 10px;
  height: 10px;
  left: 50%;
  animation: flow-s 2s linear infinite;
}
.small-l {
  width: 10px;
  height: 10px;
  left: 43%;
  animation: flow-s-l 0.9s linear infinite;
}
.s-medium {
  width: 20px;
  height: 20px;
  left: 43%;
  animation: flow-s 1.3s linear infinite;
}
.medium {
  width: 25px;
  height: 25px;
  left: 45%;
  animation: flow-m 0.8s linear infinite;
}
.large {
  width: 5px;
  height: 5px;
  left: 57%;
  animation: flow-l 0.7s linear infinite;
}
@keyframes flow-l {
  0% {
    top: 53%;
    left: 57%;
    opacity: 0;
  }
  25% {
    top: 49%;
    left: 57.5%;
    opacity: 1;
  }
  50% {
    left: 58%;
    opacity: 1;
  }
  75% {
    left: 57.5%;
    opacity: 1;
  }
  100% {
    top: 32%;
    left: 57%;
    opacity: 0;
  }
}

@keyframes flow-s {
  0% {
    top: 53%;
    left: 50%;
    opacity: 0;
  }
  25% {
    top: 49%;
    left: 51%;
    opacity: 1;
  }
  50% {
    top: 45%;
    left: 52%;
    opacity: 1;
  }
  75% {
    top: 41%;
    left: 51%;
    opacity: 1;
  }
  100% {
    top: 37%;
    left: 50%;
    opacity: 0;
  }
}

@keyframes flow-s-m {
  0% {
    top: 53%;
    left: 43%;
    opacity: 0;
  }
  25% {
    top: 49%;
    left: 42.5%;
    opacity: 1;
  }
  50% {
    top: 45%;
    left: 42%;
    opacity: 1;
  }
  75% {
    top: 41%;
    left: 42.5%;
    opacity: 1;
  }
  100% {
    top: 37%;
    left: 43%;
    opacity: 0;
  }
}

@keyframes flow-m {
  0% {
    top: 53%;
    left: 45%;
    opacity: 0;
  }
  25% {
    top: 48%;
    left: 44.5%;
    opacity: 1;
  }
  50% {
    top: 43%;
    left: 44%;
    opacity: 1;
  }
  75% {
    top: 38%;
    left: 44.5%;
    opacity: 1;
  }
  100% {
    top: 33%;
    left: 45%;
    opacity: 0;
  }
}

@keyframes flow-s-l {
  0% {
    top: 53%;
    left: 43%;
    opacity: 0;
  }
  25% {
    top: 48%;
    left: 43.5%;
    opacity: 1;
  }
  50% {
    top: 43%;
    left: 44%;
    opacity: 1;
  }
  75% {
    top: 38%;
    left: 43.5%;
    opacity: 1;
  }
  100% {
    top: 33%;
    left: 43%;
    opacity: 0;
  }
}
</style>
